.blog {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 50px 0;
  }
  
  .blog h2 {
    font-size: 36px;
    font-weight: bold;
    margin-bottom: 50px;
  }
  
  .blog_container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
  
  .one_cards {
    width: 350px;
    margin: 20px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease-in-out;
  }
  
  .one_cards:hover {
    transform: translateY(-10px);
  }
  
  .cards_blog img {
    width: 100%;
    height: 200px;
    object-fit: cover;
  }
  
  .world {
    padding: 20px;
  }
  
  .world small {
    font-size: 12px;
    color: #888;
  }
  
  .world h6 {
    font-size: 14px;
    color: #888;
    margin: 5px 0;
  }
  
  .world h4 {
    font-size: 20px;
    font-weight: bold;
    margin: 10px 0;
  }
  
  .world p {
    font-size: 14px;
    color: #888;
    margin: 10px 0;
    line-height: 1.5;
  }
  
  .icon {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 20px;
  }
  
  .sub_icon {
    display: flex;
    align-items: center;
    cursor: pointer;
  }
  .title{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    cursor: pointer;
  }
  .sub_icon, .sub_icon > * {
    cursor: pointer;
  }
  
  .sub_icon svg {
    width: 20px;
    height: 20px;
    color: #888;
    margin-left: 10px;
    cursor: pointer;
  }
  
  .sub_icon svg:hover {
    color: #333;
    cursor: pointer;
  }
  