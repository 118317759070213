.qualification {
  background-color: #f8f8f8;
  padding: 50px;
}

.qualification_header {
  text-align: center;
}
.qualification_container{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.amazingly_left,
.amazingly_right {
  margin-bottom: 30px;
}

.amazingly_left {
  margin-right: 50px;
}

.amazingly_right {
  text-align: right;
}

.alain,
.alaing {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-top: 30px;
}

h2,
h3,
h5 {
  margin: 0;
  color: #333;
}

p {
  margin: 10px 0;
  font-size: 16px;
  color: #666;
}

h2 {
  font-size: 32px;
  text-transform: uppercase;
}

h3 {
  font-size: 24px;
}

h5 {
  font-size: 16px;
  font-weight: normal;
}

/* .qualification {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 50px 20px;
  
}

.qualification_header {
  text-align: center;
  margin-bottom: 40px;
}

.qualification_header h2 {
  font-size: 36px;
  margin-bottom: 20px;
}

.qualification_header p {
  font-size: 18px;
  color: #888;
}

.qualification_container {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.alain {
  width: 40%;
}

.amazingly_left {
  margin-bottom: 30px;
}

.amazingly_left h2 {
  font-size: 24px;
  margin-bottom: 10px;
}

.amazingly_left h3 {
  font-size: 18px;
  margin-bottom: 10px;
}

.amazingly_left p {
  font-size: 16px;
  line-height: 1.5;
  color: #888;
}

.alaing {
  width: 55%;
}

.amazingly_right {
  margin-bottom: 30px;
}

.amazingly_right h4 {
  font-size: 24px;
  margin-bottom: 10px;
}

.amazingly_right h5 {
  font-size: 18px;
  margin-bottom: 10px;
}

.amazingly_right p {
  font-size: 16px;
  line-height: 1.5;
  color: #888;
} */
