.container_serivice {
    width: 80%;
    margin: auto;
    max-width: 1200px;
    padding: 20px;
  }
  
  .services {
    text-align: center;
  }
  
  .services h2 {
    font-size: 36px;
    margin-bottom: 10px;
  }
  
  .services p {
    font-size: 18px;
    margin-bottom: 30px;
  }
  
  .upper {
    display: flex;
    justify-content: space-between;
    margin-bottom: 30px;
  }
  
  .lower {
    display: flex;
    justify-content: space-between;
  }
  
  .services_details {
    flex-basis: calc(50% - 15px);
  }
  
  .services_details h4 {
    font-size: 24px;
    margin-bottom: 10px;
  }
  
  .services_details p {
    font-size: 16px;
    line-height: 1.4;
  }
  
  .services_details img {
    display: flex;
    max-width: 20%;
  }
  