.features {
    background-color: #f7f7f7;
    padding: 50px 0;
    text-align: center;
  }
  
  .features h2 {
    font-size: 40px;
    font-weight: 700;
    margin-bottom: 30px;
  }
  
  .features p {
    font-size: 20px;
    font-weight: 400;
    margin-bottom: 50px;
  }
  
  .features_container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    max-width: 1200px;
    margin: 0 auto;
  }
  
  .small_cards {
    background-color: #fff;
    border: 1px solid #eee;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    margin: 20px;
    padding: 30px;
    max-width: 298px;
    text-align: center;
    transition: all 0.3s ease;
  }
  
  .small_cards:hover {
    transform: translateY(-10px);
    box-shadow: 0 20px 25px rgba(0, 0, 0, 0.1);
    background-color: rgb(138, 197, 236);
  }
  
  .small_cards h5 {
    color: #222;
    font-size: 24px;
    font-weight: 700;
    margin-bottom: 20px;
  }
  
  .small_cards p {
    color: #777;
    font-size: 16px;
    font-weight: 400;
    line-height: 1.5;
  }
  