
.Project_container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 50px 20px;
  }

  .headers_project {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 50px;
  }
  
.project_cards {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  
  .cards {
    width: 45%;
    margin-bottom: 50px;
    padding: 20px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    transition: all 0.3s ease-in-out;
  }
  
  .cards:hover {
    transform: translateY(-5px);
    box-shadow: 0 6px 8px rgba(0, 0, 0, 0.2);
  }
  
  .image {
    width: 100%;
    height: 200px;
    overflow: hidden;
    border-radius: 10px;
  }
  
  .image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: all 0.3s ease-in-out;
  }
  
  .cards:hover .image img {
    transform: scale(1.1);
  }
  
  h3 {
    margin: 20px 0;
    font-size: 22px;
    font-weight: 600;
    color: #333;
  }
  
  p {
    margin: 0;
    font-size: 16px;
    line-height: 1.5;
    color: #666;
  }
  